import { render, staticRenderFns } from "./wordTable.vue?vue&type=template&id=15499f0b&scoped=true"
import script from "./wordTable.vue?vue&type=script&lang=js"
export * from "./wordTable.vue?vue&type=script&lang=js"
import style0 from "./wordTable.vue?vue&type=style&index=0&id=15499f0b&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15499f0b",
  null
  
)

export default component.exports