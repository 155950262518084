<template>
  <div>
    <div class="titleSty">
      {{ tableTitle }}
    </div>
    <div class="tableTop">
      <div class="tableTop">
        <div>工程名称：</div>
        <el-input v-model="tableForm.projectName" :disabled="isDisabled" class="topInputLeft"></el-input>
        <div>（印章）</div>
      </div>
      <div class="tableTop">
        <div>申请日期：</div>
        <el-input v-model="tableForm.applicationTimer" :disabled="isDisabled" class="topInput"></el-input>
      </div>
      <!-- <div class="tableTop"><div>申请日期：</div><el-input v-model="tableForm.year" :disabled="isDisabled" class="topInput"></el-input><div>年</div><el-input v-model="tableForm.month" :disabled="isDisabled" class="topInput1"></el-input><div>月</div><el-input v-model="tableForm.day" :disabled="isDisabled" class="topInput1"></el-input><div>日</div></div> -->
    </div>
    <!--设置表格属性-->
    <div class="tableBox">
      <table collapse border="'0.1"
             style="border-collapse:collapse;text-align: center;margin: 0 auto;" width="1100"
             cellspacing="0.1"
             cellpadding="1" bgcolor="" bordercolor="black"
      >
        <tr>
          <td>建设单位</td>
          <td colspan="3">
            <el-input v-model="tableForm.constructionUnit" :disabled="isDisabled"></el-input>
          </td>
          <td colspan="2">联系人</td>
          <td colspan="2">
            <el-input v-model="tableForm.contacts" :disabled="isDisabled"></el-input>
          </td>
          <td>联系电话</td>
          <td colspan="3">
            <el-input v-model="tableForm.contactsPhone" :disabled="isDisabled"></el-input>
          </td>
        </tr>
        <tr>
          <td>工程地址</td>
          <td colspan="3">
            <el-input v-model="tableForm.address" :disabled="isDisabled"></el-input>
          </td>
          <td colspan="2">类别</td>
          <td colspan="6">
            <template>
              <el-radio-group v-model="tableForm.checkListType" :disabled="isDisabled">
                <el-radio v-for="(item,index) in checklistTypeArr" :key="index" :label="item.value">{{ item.name }}
                </el-radio>
              </el-radio-group>
            </template>
          </td>
        </tr>
        <tr>
          <td colspan="2">建设工程规划许可文件（依法需办理的）</td>
          <td colspan="2">
            <el-input v-model="tableForm.permitFile" :disabled="isDisabled"></el-input>
          </td>
          <td colspan="4">临时性建筑批准文件（依法需办理的）</td>
          <td colspan="4">
            <el-input v-model="tableForm.approveFile" :disabled="isDisabled"></el-input>
          </td>
        </tr>
        <tr>
          <td colspan="2">特殊消防设计</td>
          <td colspan="2">
            <template>
              <el-radio v-for="(item,index) in optionsBoolArr" :key="index" v-model="tableForm.specialFire"
                        :label="item.value" :disabled="isDisabled">{{ item.name }}
              </el-radio>
            </template>
          </td>
          <td colspan="4">建筑高度大于250m的建筑采取强制性消防设计措施</td>
          <td colspan="4">
            <template>
              <el-radio v-for="(item,index) in optionsBoolArr" :key="index" v-model="tableForm.buildingHeight"
                        :label="item.value" :disabled="isDisabled">{{ item.name }}
              </el-radio>
            </template>
          </td>
        </tr>
        <tr>
          <td colspan="2">工程投资额（万元）</td>
          <td colspan="2">
            <el-input v-model="tableForm.investment" :disabled="isDisabled"></el-input>
          </td>
          <td colspan="4">总建筑面积（m²）</td>
          <td colspan="4">
            <el-input v-model="tableForm.coveredArea" :disabled="isDisabled"></el-input>
          </td>
        </tr>
        <tr>
          <td colspan="4">特殊建设工程情形（详见背面）</td>
          <td colspan="8">
            <template>
              <el-checkbox-group v-model="tableForm.specialBuilding" :disabled="isDisabled">
                <el-checkbox label="1">（一）</el-checkbox>
                <el-checkbox label="2">（二）</el-checkbox>
                <el-checkbox label="3">（三）</el-checkbox>
                <el-checkbox label="4">（四）</el-checkbox>
                <el-checkbox label="5">（五）</el-checkbox>
                <el-checkbox label="6">（六）</el-checkbox>
                <el-checkbox label="7">（七）</el-checkbox>
                <el-checkbox label="8">（八）</el-checkbox>
                <el-checkbox label="9">（九）</el-checkbox>
                <el-checkbox label="10">（十）</el-checkbox>
                <el-checkbox label="11">（十一）</el-checkbox>
                <el-checkbox label="12">（十二）</el-checkbox>
              </el-checkbox-group>
            </template>
          </td>
        </tr>
        <tr>
          <td colspan="2">单位类别</td>
          <td colspan="2">单位名称</td>
          <td colspan="1">资质等级</td>
          <td colspan="2">法定代表人（身份证号）</td>
          <td colspan="2">项目负责人（身份证号）</td>
          <td colspan="3">联系电话（移动电话和座机）</td>
        </tr>

        <tr v-for="(item,index) in tableForm.companysArr" :key="item.id">
          <td colspan="2">
            <el-input v-model="item.companyTypeName" :disabled="isDisabled"></el-input>
          </td>
          <td colspan="2">
            <el-input v-model="item.companyName" :disabled="isDisabled"></el-input>
          </td>
          <td colspan="1">
            <el-input v-model="item.qualificationLevel" :disabled="isDisabled"></el-input>
          </td>
          <td colspan="2">
            <el-input v-model="item.corporateCard" :disabled="isDisabled"></el-input>
          </td>
          <td colspan="2">
            <el-input v-model="item.projectDirectorCard" :disabled="isDisabled"></el-input>
          </td>
          <td colspan="2">
            <el-input v-model="item.phone" :disabled="isDisabled"></el-input>
          </td>
          <td v-if="index == tableForm.companysArr.length - 1">
            <div class="identifierBox">
              <div class="identifier" style="margin-right: 5px;" @click="companysArrAdd">+</div>
              <div class="identifier" @click="companysArrDel">-</div>
            </div>
          </td>
        </tr>

        <tr>
          <td rowspan="2">建筑名称</td>
          <td rowspan="2">结构类型</td>
          <td rowspan="2">使用性质</td>
          <td rowspan="2">耐火等级</td>
          <td colspan="2">层数</td>
          <td rowspan="2">高度（m）</td>
          <td rowspan="2">长度（m）</td>
          <td rowspan="2">占地面积（m²）</td>
          <td colspan="3">建筑面积（m²）</td>
        </tr>
        <tr>
          <td>地上</td>
          <td>地下</td>
          <td>地上</td>
          <td colspan="2">地下</td>
        </tr>

        <tr v-for="(item,index) in tableForm.buildsArr" :key="index">
          <td>
            <el-input v-model="item.buildName" :disabled="isDisabled"></el-input>
          </td>
          <td>
            <el-input v-model="item.structureType" :disabled="isDisabled"></el-input>
          </td>
          <td>
            <el-input v-model="item.useCharacter" :disabled="isDisabled"></el-input>
          </td>
          <td>
            <el-input v-model="item.fireRating" :disabled="isDisabled"></el-input>
          </td>
          <td>
            <el-input v-model="item.overgroundFloors" :disabled="isDisabled"></el-input>
          </td>
          <td>
            <el-input v-model="item.undergroundFloors" :disabled="isDisabled"></el-input>
          </td>
          <td>
            <el-input v-model="item.height" :disabled="isDisabled"></el-input>
          </td>
          <td>
            <el-input v-model="item.length" :disabled="isDisabled"></el-input>
          </td>
          <td>
            <el-input v-model="item.landArea" :disabled="isDisabled"></el-input>
          </td>
          <td>
            <el-input v-model="item.overgroundArea" :disabled="isDisabled"></el-input>
          </td>
          <td>
            <el-input v-model="item.undergroundArea" :disabled="isDisabled"></el-input>
          </td>
          <td v-if="index == tableForm.buildsArr.length - 1">
            <div class="identifierBox">
              <div class="identifier" style="margin-right: 5px;" @click="buildsArrAdd">+</div>
              <div class="identifier" @click="buildsArrDel">-</div>
            </div>
          </td>
        </tr>

        <tr>
          <td rowspan="2">
            <template>
              <el-checkbox v-model="tableForm.decorate" label="1" :disabled="isDisabled">装饰装修</el-checkbox>
            </template>
          </td>
          <td colspan="2">装修部位</td>
          <td colspan="9">
            <template>
              <el-checkbox-group v-model="tableForm.decorationPart" :disabled="isDisabled">
                <el-checkbox label="1">顶棚</el-checkbox>
                <el-checkbox label="2">墙面</el-checkbox>
                <el-checkbox label="3">地面</el-checkbox>
                <el-checkbox label="4">隔断</el-checkbox>
                <el-checkbox label="5">固定家具</el-checkbox>
                <el-checkbox label="6">装饰织物</el-checkbox>
                <el-checkbox label="7">其他</el-checkbox>
              </el-checkbox-group>
            </template>
          </td>
        </tr>
        <tr>
          <td colspan="2">装修面积（m²）</td>
          <td colspan="3">
            <el-input v-model="tableForm.decorationArea" :disabled="isDisabled"></el-input>
          </td>
          <td colspan="2">装修所在层数</td>
          <td colspan="5">
            <el-input v-model="tableForm.decorationFloor" :disabled="isDisabled"></el-input>
          </td>
        </tr>
        <tr>
          <td>
            <template>
              <el-checkbox v-model="tableForm.changeUse" label="1" :disabled="isDisabled">改变用途</el-checkbox>
            </template>
          </td>
          <td colspan="2">使用性质</td>
          <td colspan="3">
            <el-input v-model="tableForm.useNature" :disabled="isDisabled"></el-input>
          </td>
          <td colspan="2">原有用途</td>
          <td colspan="4">
            <el-input v-model="tableForm.originalUse" :disabled="isDisabled"></el-input>
          </td>
        </tr>
        <tr>
          <td rowspan="2">
            <template>
              <el-checkbox v-model="tableForm.buildingThermal" label="1" :disabled="isDisabled">建筑保温</el-checkbox>
            </template>
          </td>
          <td colspan="2">材料类别</td>
          <td colspan="3">
            <template>
              <el-checkbox-group v-model="tableForm.materialCategory" :disabled="isDisabled">
                <el-checkbox label="1">A</el-checkbox>
                <el-checkbox label="2">B1</el-checkbox>
                <el-checkbox label="3">B2</el-checkbox>
              </el-checkbox-group>
            </template>
          </td>
          <td colspan="2">保温所在层数</td>
          <td colspan="4">
            <el-input v-model="tableForm.thermalLayer" :disabled="isDisabled"></el-input>
          </td>
        </tr>
        <tr>
          <td colspan="2">保温部位</td>
          <td colspan="3">
            <el-input v-model="tableForm.thermalArea" :disabled="isDisabled"></el-input>
          </td>
          <td colspan="2">保温材料</td>
          <td colspan="4">
            <el-input v-model="tableForm.thermalmaterial" :disabled="isDisabled"></el-input>
          </td>
        </tr>
        <tr>
          <td>消防设施及其他</td>
          <td colspan="11">
            <template>
              <el-checkbox-group v-model="tableForm.fireFightingDevice" :disabled="isDisabled">
                <el-checkbox label="1">室内消火栓系统</el-checkbox>
                <el-checkbox label="2">室外消火栓系统</el-checkbox>
                <el-checkbox label="3">火灾自动报警系统</el-checkbox>
                <el-checkbox label="4">自动喷水灭火系统</el-checkbox>
                <el-checkbox label="5">气体灭火系统</el-checkbox>
                <el-checkbox label="6">泡沫灭火系统</el-checkbox>
                <el-checkbox label="7">其他灭火系统</el-checkbox>
                <el-checkbox label="8">疏散指示标志</el-checkbox>
                <el-checkbox label="9">消防应急照明</el-checkbox>
                <el-checkbox label="10">防烟排烟系统</el-checkbox>
                <el-checkbox label="11">消防电梯</el-checkbox>
                <el-checkbox label="12">灭火器</el-checkbox>
                <el-checkbox label="13">其他</el-checkbox>
              </el-checkbox-group>
            </template>
          </td>
        </tr>
        <tr>
          <td>工程简要说明</td>
          <td colspan="11">
            <template>
              <el-input
                  type="textarea"
                  :rows="4"
                  placeholder="请输入内容"
                  v-model="tableForm.engineeringDescription"
                  :disabled="isDisabled"
              >
              </el-input>
            </template>
          </td>
        </tr>
      </table>
      <div v-if="!dataHideBtn" slot="footer" class="dialog-footer bottomBtn">
        <el-button type="primary" @click="showConfirmDialogSubmit" :loading="showConfirmDialogLoading">确认</el-button>
        <el-button type="primary" @click="uploadFileSubmit">上传识别文件</el-button>
      </div>
    </div>
    <!-- 文件上传 -->
    <div class="uploadModule" v-if="uploadFileDialog"></div>
    <div
        class="uploadSty"
        v-if="uploadFileDialog"
    >
      <el-upload
          class="upload-demo"
          drag
          :action="uploadImagesUrl"
          :data="{projectId: projectId}"
          multiple
          :headers="headers"
          :before-upload="beforeUpload"
          :on-success="handle_success"
          :on-error="handle_error"
          :show-file-list="false"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>
      <div slot="footer" class="dialog-footer" style="text-align: right;">
        <el-button @click.native="uploadFileDialog = false">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import util from '../../../util/date'
import {
  addProjectApplication,
  updateProjectApplication,
  getProjectApplicationByProjectId,
  uploadApplicationPic
} from '@/api/api'
import api from '@/api'

function processResponse (resp) {
  let tableForm = {
    id: '',
    // year: '', //年
    // month: '', //月
    // day: '', //日
    projectName: '',
    applicationTimer: '',
    constructionUnit: resp.contractor, //建设单位
    contacts: resp.contacts, //联系人
    contactsPhone: resp.phone, //联系电话
    address: resp.address, //工程地址
    permitFile: resp.licenseFile, //建设工程规划许可文件（依法需办理的）
    approveFile: resp.approvalFile, //临时性建筑批准文件（依法需办理的）
    investment: resp.totalInvestment, //工程投资额（万元）
    coveredArea: resp.totalArea, //总建筑面积（m²）

    companysArr: [
      {
        companyTypeName: '',  //单位类别
        companyName: '',  //单位名称
        qualificationLevel: '',  //单位资质等级
        corporateCard: '',  //单位法定代表人（身份证号）
        projectDirectorCard: '',  //单位项目负责人（身份证号）
        phone: '',  //联系电话（移动电话和座机）
      },
    ],
    buildsArr: [
      {
        buildName: '', //建筑名称
        structureType: '', //结构类型
        useCharacter: '', //使用性质
        fireRating: '', //耐火等级
        overgroundFloors: '', //层数上
        undergroundFloors: '', //层数下
        height: '', //高度
        length: '', //长度
        landArea: '', //占地面积
        overgroundArea: '', //建筑面积上
        undergroundArea: '', //建筑面积下
      },
    ],

    decorationArea: resp.decorationArea,  //装修面积
    decorationFloor: resp.decorationFloor,  //装修所在层数
    useNature: resp.useCharacter,  //使用性质
    originalUse: resp.originalUse,  //原有用途
    thermalLayer: resp.keepWarmFloor,  //保温所在层数
    thermalArea: resp.keepWarmPosition,  //保温部位
    thermalmaterial: resp.keepWarmMaterial,  //保温材料
    checkListType: resp.ddIdCategory,  //类别多选
    specialFire: resp.isSpecialFire,  //特殊消防设计
    buildingHeight: resp.isStrengthenFire,  //建筑高度
    decorate: resp.isDecoration ? ['1'] : [],  //装饰装修
    changeUse: resp.isChangePurpose ? ['1'] : [],  //改变用途
    buildingThermal: resp.isKeepWarm ? ['1'] : [],  //建筑保温
    engineeringDescription: resp.notes,  //工程简要说明
    specialBuilding: resp.specialSituations ? resp.specialSituations : [], //特殊建筑
    decorationPart: resp.decorationPositions ? resp.decorationPositions : [], //装修部位
    materialCategory: resp.materialTypes ? resp.materialTypes : [], //材料类别
    fireFightingDevice: resp.fireFacilitiess ? resp.fireFacilitiess : [], //消防设施及其他
  }
  if (!tableForm.id || tableForm.id ===null){
    tableForm.id = this.Id
  }
  // 工程名称/申请日期
  if (resp.projectName) {
    tableForm.projectName = resp.projectName
    tableForm.applicationTimer = this.formatCreateTime(resp.applicationTime)
  } else if (this.needData) {
    tableForm.projectName = this.needData.projectName
    tableForm.applicationTimer = this.formatCreateTime(this.needData.projectPeriod)
  }
  // 单位类别
  if (resp.companys && resp.companys.length > 0) {
    resp.companys.forEach((companyInfo, index) => {
      if (index < tableForm.companysArr.length) {
        tableForm.companysArr[index] = { ...companyInfo };
      } else {
        tableForm.companysArr.push({ ...companyInfo });
      }
    });
  } else {
    tableForm.companysArr = [
      {
        companyTypeName: '',  //单位类别
        companyName: '',  //单位名称
        qualificationLevel: '',  //单位资质等级
        corporateCard: '',  //单位法定代表人（身份证号）
        projectDirectorCard: '',  //单位项目负责人（身份证号）
        phone: '',  //联系电话（移动电话和座机）
      },
    ]
  }
  // 建筑名称
  if (resp.builds && resp.builds.length > 0) {
    resp.builds.forEach((companyInfo, index) => {
      if (index < tableForm.buildsArr.length) {
        tableForm.buildsArr[index] = { ...companyInfo };
      } else {
        tableForm.buildsArr.push({ ...companyInfo });
      }
    });
  } else {
    tableForm.buildsArr = [
      {
        buildName: '', //建筑名称
        structureType: '', //结构类型
        useCharacter: '', //使用性质
        fireRating: '', //耐火等级
        overgroundFloors: '', //层数上
        undergroundFloors: '', //层数下
        height: '', //高度
        length: '', //长度
        landArea: '', //占地面积
        overgroundArea: '', //建筑面积上
        undergroundArea: '', //建筑面积下
      },
    ]
  }
  return tableForm
}

export default {
  data () {
    return {
      tableTitle: '特殊/普通/改造建设工程消防设计审查申请表',
      showConfirmDialogLoading: false,
      tableForm: {
        id: '',
        projectName: '',  //工程名称
        year: '', //年
        month: '', //月
        day: '', //日
        applicationTimer: '', //申请日期
        constructionUnit: '', //建设单位
        contacts: '', //联系人
        contactsPhone: '', //联系电话
        address: '', //工程地址
        permitFile: '', //建设工程规划许可文件（依法需办理的）
        approveFile: '', //临时性建筑批准文件（依法需办理的）
        investment: '', //工程投资额（万元）
        coveredArea: '', //总建筑面积（m²）

        companysArr: [
          {
            companyTypeName: '',  //单位类别
            companyName: '',  //单位名称
            qualificationLevel: '',  //单位资质等级
            corporateCard: '',  //单位法定代表人（身份证号）
            projectDirectorCard: '',  //单位项目负责人（身份证号）
            phone: '',  //联系电话（移动电话和座机）
          },
        ],
        buildsArr: [
          {
            buildName: '', //建筑名称
            structureType: '', //结构类型
            useCharacter: '', //使用性质
            fireRating: '', //耐火等级
            overgroundFloors: '', //层数上
            undergroundFloors: '', //层数下
            height: '', //高度
            length: '', //长度
            landArea: '', //占地面积
            overgroundArea: '', //建筑面积上
            undergroundArea: '', //建筑面积下
          },
        ],

        buildingName1: '',  //建筑名称
        structureType1: '',  //结构类型
        useNature1: '',  //使用性质
        fireresistance1: '',  //耐火等级
        pliesTop1: '',  //层数上
        pliesBott1: '',  //层数下
        height1: '',  //高度
        long1: '',  //长度
        occupiedArea1: '',  //占地面积
        areaTop1: '',  //建筑面积上
        areaBott1: '',  //建筑面积下
        buildingName2: '',  //建筑名称
        structureType2: '',  //结构类型
        useNature2: '',  //使用性质
        fireresistance2: '',  //耐火等级
        pliesTop2: '',  //层数上
        pliesBott2: '',  //层数下
        height2: '',  //高度
        long2: '',  //长度
        occupiedArea2: '',  //占地面积
        areaTop2: '',  //建筑面积上
        areaBott2: '',  //建筑面积下

        decorationArea: '',  //装修面积
        decorationFloor: '',  //装修所在层数
        useNature: '',  //使用性质
        originalUse: '',  //原有用途
        thermalLayer: '',  //保温所在层数
        thermalArea: '',  //保温部位
        thermalmaterial: '',  //保温材料
        checkListType: '',  //类别多选
        specialFire: '',  //特殊消防设计
        buildingHeight: '',  //建筑高度
        specialBuilding: [],  //特殊建筑
        decorate: [],  //装饰装修
        decorationPart: [],  //装修部位
        changeUse: [],  //改变用途
        buildingThermal: [],  //建筑保温
        materialCategory: [],  //材料类别
        fireFightingDevice: [],  //消防设施及其他
        engineeringDescription: '',  //工程简要说明
      },
      uploadFileDialog: false,
      uploadImagesUrl: '',
      checklistTypeArr: [
        { value: 39, name: '新建' },
        { value: 40, name: '扩建' },
        { value: 41, name: '改建（装饰装修、改变用途、建筑保温）' },
      ],
      optionsBoolArr: [
        { value: true, name: '是' },
        { value: false, name: '否' },
      ],
    }
  },
  props: ['dataHideBtn', 'projectId', 'isDisabled', 'needData'],
  methods: {
    // 确定提交
    showConfirmDialogSubmit () {
      var _this = this
      var params = {
        id:this.tableForm.id,
        projectId: this._props.projectId,
        address: this.tableForm.address,
        approvalFile: this.tableForm.approveFile,
        contacts: this.tableForm.contacts,
        contractor: this.tableForm.constructionUnit,
        ddIdCategory: this.tableForm.checkListType,
        decorationArea: this.tableForm.decorationArea,
        decorationFloor: this.tableForm.decorationFloor,
        decorationPositions: this.tableForm.decorationPart,
        fireFacilitiess: this.tableForm.fireFightingDevice,
        isChangePurpose: this.tableForm.changeUse.length > 0 ? true : false,
        isDecoration: this.tableForm.decorate.length > 0 ? true : false,
        isKeepWarm: this.tableForm.buildingThermal.length > 0 ? true : false,
        isSpecialFire: this.tableForm.specialFire,
        isStrengthenFire: this.tableForm.buildingHeight,
        keepWarmFloor: this.tableForm.thermalLayer,
        keepWarmMaterial: this.tableForm.thermalmaterial,
        keepWarmPosition: this.tableForm.thermalArea,
        licenseFile: this.tableForm.permitFile,
        materialTypes: this.tableForm.materialCategory,
        notes: this.tableForm.engineeringDescription,
        originalUse: this.tableForm.originalUse,
        phone: this.tableForm.contactsPhone,
        specialSituations: this.tableForm.specialBuilding,
        totalArea: this.tableForm.coveredArea,
        totalInvestment: this.tableForm.investment,
        useCharacter: this.tableForm.useNature,
        buildReqs: this.tableForm.buildsArr,
        companyReqs: this.tableForm.companysArr
      }
      this.showConfirmDialogLoading = true
      if (this.Id===null) {
        console.log(params)
        addProjectApplication(params).then(res => {
          var result = res.data
          if (result.success) {
            this.$message.success(result.message)
            this.$emit('closeThisDialog')
            // setTimeout(() => {
            //     _this.$router.back(-1)
            // },500)
          } else {
            this.$message.error(result.message)
          }
          this.showConfirmDialogLoading = false
        })
      } else {
        params.id = this.Id
        updateProjectApplication(params).then(res => {
          var result = res.data
          if (result.success) {
            this.$message.success(result.message)
            this.$emit('closeThisDialog')
            // setTimeout(() => {
            //     _this.$router.back(-1)
            // },500)
          } else {
            this.$message.error(result.message)
          }
          this.showConfirmDialogLoading = false
        })
      }
    },
    // 上传识别文件
    uploadFileSubmit () {
      this.uploadFileDialog = true
    },
    // 获取申请表
    getapplication () {
      let params = {
        projectId: this._props.projectId
      }
      getProjectApplicationByProjectId(params).then(res => {
        let result = res.data
        if (result.success) {
          let resp = result.response
          this.tableForm = processResponse.call(this, resp)
          this.Id  = resp.id
          let idArray = this.tableForm.companysArr.map(company => {
            return {
              id: company.id,
              applicationId: company.applicationId, // 替换为实际的属性名
            };
          });
          let idArray1 = this.tableForm.buildsArr.map(company => {
            return {
              id: company.id,
              applicationId: company.applicationId, // 替换为实际的属性名
            };
          });
          this.idArray = idArray
          this.idArray1 = idArray1
        }
      })
    },
    // 单位类别新增/删除
    companysArrAdd () {
      var newObj = {
        companyTypeName: '',  //单位类别
        companyName: '',  //单位名称
        qualificationLevel: '',  //单位资质等级
        corporateCard: '',  //单位法定代表人（身份证号）
        projectDirectorCard: '',  //单位项目负责人（身份证号）
        phone: '',  //联系电话（移动电话和座机）
      }
      this.tableForm.companysArr.push(newObj)
    },
    companysArrDel () {
      if (this.tableForm.companysArr.length > 1) {
        this.tableForm.companysArr.pop()
      } else {
        this.$message.warning('无法再继续删除！')
      }
    },
    // 建筑名称新增/删除
    buildsArrAdd () {
      var newObj = {
        buildName: '', //建筑名称
        structureType: '', //结构类型
        useCharacter: '', //使用性质
        fireRating: '', //耐火等级
        overgroundFloors: '', //层数上
        undergroundFloors: '', //层数下
        height: '', //高度
        length: '', //长度
        landArea: '', //占地面积
        overgroundArea: '', //建筑面积上
        undergroundArea: '', //建筑面积下
      }
      this.tableForm.buildsArr.push(newObj)
    },
    buildsArrDel () {
      if (this.tableForm.buildsArr.length > 1) {
        this.tableForm.buildsArr.pop()
      } else {
        this.$message.warning('无法再继续删除！')
      }
    },
    // 图片上传之前函数
    beforeUpload (file) {
      const isLt2M = file.size / 1024 / 1024 > 2
      var type = file.name.substring(file.name.lastIndexOf('.') + 1)
      if (type == 'jpg' || type == 'png') {} else {
        this.$message.error('上传图片只能是 JPG/PNG 格式!')
        return false
      }
      if (!isLt2M === false) {
        this.$message.error('上传图片大小不能超过 2MB!')
        return false
      }
    },
    // 图片上传成功时的函数
    handle_success (res) {
      // console.log(res)
      if (res.success) {
        this.$message.success('图片上传成功！')
        this.uploadFileDialog = false;
        // this.getapplication();
        if (res.success) {
          let resp = res.response
          this.tableForm = processResponse.call(this, resp)
          // resp.id = this.Id
          const newPropertyValues = this.idArray
          const newPropertyValues1 = this.idArray1
          if (resp.companys && resp.companys.length > 0) {
            resp.companys.forEach((companyInfo, index) => {
              if (index < this.tableForm.companysArr.length) {
                // 获取对应的新属性值
                const newPropertyValue = newPropertyValues[index];
                // 更新 this.tableForm 中的属性值
                this.tableForm.companysArr[index] = {
                  ...this.tableForm.companysArr[index], // 保留原有属性
                  id: newPropertyValue.id, // 替换为实际属性名
                  applicationId: newPropertyValue.applicationId
                };
              }
            });
          }
          if (resp.builds && resp.builds.length > 0) {
            resp.builds.forEach((companyInfo, index) => {
              if (index < this.tableForm.buildsArr.length) {
                // 获取对应的新属性值
                let newPropertyValue = newPropertyValues1[index];
                // 更新 this.tableForm 中的属性值
                this.tableForm.buildsArr[index] = {
                  ...this.tableForm.buildsArr[index], // 保留原有属性
                  id: newPropertyValue.id, // 替换为实际属性名
                  applicationId: newPropertyValue.applicationId
                };
              }
            });
          }

        }
      } else {
        this.$message.error(res.message)
      }
    },
    // 图片上传失败时的函数
    handle_error (res) {
      this.$message.error('图片上传失败！')
    },
    // 获取token
    getToken () {
      return localStorage.getItem('Token')
    },
    // 时间格式化
    formatCreateTime: function (row, column) {
      return !row || row == ''
          ? ''
          : util.formatDate.format(new Date(row), 'yyyy-MM-dd hh:mm:ss')
    }
  },
  computed: {
    headers () {
      return {
        'Authorization': 'Bearer ' + this.getToken() // 直接从本地获取token就行
      }
    },
  },
  watch: {
    'projectId': function (val) {
      this.getapplication()
    },
  },
  created () {
    this.uploadImagesUrl = api.uploadApplicationPicUrl
    this.getapplication()
  }
}
</script>

<style lang="stylus" scoped>
.titleSty {
  text-align: center;
  font-size: 17px;
  font-weight: bold;
}

.tableTop {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 8px 0;
}

.topInputLeft {
  width: 250px;
  margin: 0 10px;
}

.topInput {
  width: 200px;
  margin: 0 10px;
}

.topInput1 {
  width: 60px;
  margin: 0 10px;
}

.bottomBtn {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding-bottom: 40px;
}

.uploadSty {
  position: fixed;
  top: 20%;
  right: 37%;
  z-index: 10;
  background-color: #fff;
  padding: 30px;
}

.uploadModule {
  background: #606266;
  opacity: .4;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
}

.identifierBox {
  display: flex;
  justify-content: center;
}

.identifier {
  border: 1px solid #409eff;
  border-radius: 50%;
  color: #409eff;
  width: 20px;
  height: 20px;
  line-height: 16px;
  text-align: center;
  font-weight: bolder;
  font-size: 26px;
  cursor: pointer;
}
</style>
