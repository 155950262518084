<template>
	<!-- <div style="height:790px;background:#39B3FF;"> -->
	<div>
		<div class="boxWelcomeTitle">
			<div v-if="boxWelcomeStatus"></div>
			<!-- <div @click="backWelcome()" style="cursor: pointer;" v-else>
				<img style="padding-left: 15px;margin-top:7px;" src="../assets/quit.png" />
			</div> -->
			<!-- <div>社会消防安全教育培训管理系统</div> -->
			<div></div>
		</div>
		<div style="background-image:url('http://124.71.26.215:82/img/bg.png');position: relative;" :style="{height:pageHeight + 'px'}">
			<img style="display: none;" src="../assets/bg.png" alt="">
			<div style="width: 97%;margin: 0 auto;">
				<el-row>
					<el-col :span="6">
					</el-col>
					<el-col :span="12">
					</el-col>
					<el-col :span="6">
					</el-col>
				</el-row>
			</div>
		</div>
	</div>
</template>
<script>
var echarts = require('echarts');
import { cityMapFun } from "../../util/map";
import { getStudentLearnRecords,getJsonFile,examenrollCountGroupByProjectNameByID,getParticipatingJurisdictionsRegion,getProportionCertificateHoldersByID,getSocialUnitCertificateHoldersStatistics,getHomeSocialUnitStatistics,getSocialUnitListPage,getAreaDictionaryList,getUnitsAndCertificatesResponseByID,getUnitsAndCertificatesSingleResponseByID } from '@/api/api'
// 改版接口
import { getCertificateCountGroupByDate,getCertificateCountGroupByTradeByID,getCurrentLevelCertificateHoldingByID,getCertificateHoldingUnderTheJurisdiction,getCertificateCountByID,getMapOfCertificateHolding } from '@/api/api'
import { getAttendTrainingCountGroupByDate,getTrainingCountGroupByTradeByID,getCurrentLevelParticipationByID,getMapRegistrationRateResponseStatistics,getParticipatingJurisdictions,getTrainingCountByID,getTrainingMap } from '@/api/api'
import { getExamenrollCountGroupByDateByID,getTrainingCountGroupByTradeByIDByType,getUnitAndRegistrationCountRateStatisticsByID,getUnitAndRegistrationCountRateStatistics } from '@/api/api'
import { getMaterialExamPaperRightRatio,getToBeSupervisedSocialUnitCount,getTrainingExaminationCertificateStatistics,getTrainingExaminationCertificateByID,getUnitAndSupervisionResponseStatistics,getAgencyInfoPass } from '@/api/api'
export default {
	name: "Welcome",
	data() {
		return {
			pageHeight: 830,
			tableHeight1: 318,
			tableHeight2: 357,
			mapHeight: 520,
			unitBoxHeight: 380,
			boxWelcomeStatus: true,
			knowTableData: [],
			tbaleData1: [],
			tbaleData2: [],
			tbaleData3: [],
			isShow: false,
			files: {
				SocialUnit: 0,
				learningSocialUnit: 0,
				btainCertificate: 0,
				supervisionUnit: 0
			},
			baseData: {
				isShowPie: true,
				isShowTable1: false,
				isShowTable2: true,
				isShowTable3: false,
				cityName: '',
			},
			guangdongData: [],
			slipupMapArr: [],
			userDataParams: '',
			// 地图底部切换按钮值
			buttonActive: 0,
			// 各个分块的提示标题信息
			leftTopeLineTitle: '每日报考人数',
			leftBottomBarTitleOne: '待监管单位数',
			leftBottomBarTitleTwo: '单位数量',
			rightTableTitle: '下辖区域概况',
			rightTableOneTitle: '下辖区域概况',
			middleTopPieceTitle: {
				PieceTitleOne: '应训人数（人）',
				PieceTitleTwo: '报考人数（人）',
				PieceTitleThree: '参训人数（人）',
				PieceTitleFour: '持证数量',
			},
			middleMapTitle: {
				mapTitleOne: '人员参训率<30%',
				mapTitleTwo: '30%≤人员参训率≤70%',
				mapTitleThree: '人员参训率>70%',
			},
			rightTopPieTitle: '机构培训情况',
			// 待监管单位/单位数量
			dataType: "1",
			// 浮窗
			floatPostWinArr: [
				{name: "安全管理人，责任人",value: 2200},
				{name: "单位员工",value: 2255},
				{name: "中小企业",value: 2265},
			],
			floatIndustrytWinArr: [
				{name: "高层办公建筑",value: 2200},
				{name: "医疗机构",value: 2255},
				{name: "文管系统",value: 2265},
			],
			mapColor1: '#13b5b1',
			mapColor2: '#ca505b',
			isHiddenMap: true,
			tableAverage: 0,
			isShowBarChart: true,
			isShowNavBar: 10000,
			GeoJson: '',
			unitFirer: 0,
			stationFirer: 0,
		}
	},
	methods: {
		// 线性图
		getViewlineTable(paramsObj){
			var lineTableChart = echarts.init(document.getElementById('lineTable'));
			let xData = [];
			let firstIndustry = [];
			var params = {
				provinceID: 1919
			}
			if(paramsObj) {
				params.cityID = paramsObj.CityID
				params.regionID = paramsObj.RegionID
				params.townID = paramsObj.TownID
			}
			this.userDataParams = params
			var getDataApi = ''
			if(this.buttonActive == 3) {
				getDataApi = getCertificateCountGroupByDate
			} else if(this.buttonActive == 2) {
				getDataApi = getAttendTrainingCountGroupByDate
			} else if(this.buttonActive == 1) {
				getDataApi = getExamenrollCountGroupByDateByID
			}
			getDataApi(params).then((res) => {
				var result = res.data
				if(result.Success) {
					xData = result.Response.Xdata
					firstIndustry = result.Response.FirstIndustry
					lineTableChart.setOption({
						backgroundColor: 'rgba(6, 23, 64, 0.8)',
						grid: {
							x: 0,
							y: 0,
							x2: 0,
							y2: 0,
							top: '15%',
							left: '2%',
							right: '2%',
							bottom: '10%',
							containLabel: true,
						},
						tooltip: {
							trigger: 'axis',
							axisPointer: {
								type: 'cross',
								crossStyle: {
									color: '#999'
								}
							}
						},
						graphic:{
							type:"text",
							left:"1%",
							top:"2%",
							style:{
								text:this.leftTopeLineTitle,
								textAlign:"center",
								fill:"#fff",
								fontSize:13,
								fontWeight:300
							}
						},
						legend: {
							orient: 'vertical',
							left: 'center',
							bottom: 'bottom',
							padding: [10, 0, 0, 0],
							//图例滚动
							// type: 'scroll',
							//图例文字样式
							textStyle: {
								color: 'rgba(36, 173, 254, 1)',
								fontSize:"0.9rem"
							}
						},
						xAxis: [
							{
								type: 'category',
								axisTick: {
									show: false,
								},
								axisLabel: {
									color: 'rgba(36, 173, 254, 1)',
									fontSize:"0.8rem",
									interval: 0,
									rotate: 30,
								},
								axisLine: {
									show: false
								},
								data: xData,
							}
						],
						yAxis: [
							{
								type: 'value',
								name: '',
								// min: 0,
								// max: 10000,
								// interval: 20,
								axisLabel: {
									formatter: '{value} '
								},
								axisLabel: {
									textStyle: {
										//坐标轴颜色
										color: 'rgba(36, 173, 254, 1)',
										fontSize:"0.9rem"
									}
								},
								//坐标轴线样式
								splitLine: {
									show: true,
									lineStyle: {
										type: 'solid', //solid实线;dashed虚线
										color: 'rgba(36, 173, 254, 0.2)',
									}
								},
							},
							{
								type: 'value',
								name: '',
								// min: 0,
								// max: 10000,
								// interval: 20,
								axisLabel: {
									formatter: '{value}'
								},
								axisLabel: {
									textStyle: {
										//坐标轴颜色
										color: 'rgba(36, 173, 254, 1)',
										fontSize:"0.9rem"
									}
								},
								//坐标轴线样式
								splitLine: {
									show: true,
									lineStyle: {
										type: 'solid',
										color: 'rgba(36, 173, 254, 0.2)',
									}
								},
							}
						],
						series: [
							{
								name: "",
								data: firstIndustry,
								type: 'line',
								smooth: true, //true曲线; false折线
								itemStyle: {
									normal: {
										color: '#ffc600', //改变折线点的颜色
										lineStyle: {
											color: '#ffc600', //改变折线颜色
											type: 'solid',
											width: 1
										}
									}
								},
								areaStyle: {
									//折线图颜色半透明
									color: {
										type: 'linear',
										x: 0,
										y: 0,
										x2: 0,
										y2: 1,
										colorStops: [{
											offset: 0, color: 'rgba(255,198,0, 0.5)' // 0% 处的颜色
										}, {
											offset: 1, color: 'rgba(255,198,0, 0.1)' // 100% 处的颜色
										}],
										global: false // 缺省为 false
									}
								}
							},
						]
					});
				}
			})
		},
		// 左下角柱状图
		getViewunitSituation(paramsObj){
			var _this = this
			var className=[];
			var cityData1=[];
			var cityData2=[];
			var params = {
				provinceID: 1919,
			}
			if(paramsObj) {
				params.cityID = paramsObj.CityID
				params.regionID = paramsObj.RegionID
				params.townID = paramsObj.TownID
			}
			var getDataApi = ''
			if(this.buttonActive == 3) {
				getDataApi = getCertificateCountGroupByTradeByID
			} else if(this.buttonActive == 2) {
				getDataApi = getTrainingCountGroupByTradeByID
			} else if(this.buttonActive == 1) {
				getDataApi = getTrainingCountGroupByTradeByIDByType
				params.type = this.dataType
			} else {
				getDataApi = getToBeSupervisedSocialUnitCount
			}
				// getDataApi = examenrollCountGroupByProjectNameByID // 原版接口
			getDataApi(params).then((res) => {
				var result = res.data
				if(result.Success) {
					if(this.buttonActive == 1 || this.buttonActive == 2 || this.buttonActive == 3) {
						var newName = []
						var newData = []
						var newNum = 0
						result.Response.forEach(item => {
							if(item.Xdata == "企业志愿消防队员") {
								newNum += item.FirstIndustry
								this.unitFirer = item.FirstIndustry
							} else if(item.Xdata == "微型消防站队员") {
								newNum += item.FirstIndustry
								this.stationFirer = item.FirstIndustry
							} else {
								newName.push(item.Xdata)
								newData.push(item.FirstIndustry)
							}
						})
						newName.push("企业志愿消防队员、微型消防站队员、易燃易爆危险品从业人员")
						newData.push(newNum)
						result.Response.Xdata = newName
						result.Response.FirstIndustry = newData
					}
					className = result.Response.Xdata
					cityData1 = result.Response.FirstIndustry
					cityData2 = result.Response.SecondIndustry
					_this.isShowBarChart = false
					if(className && className.length * 35 > 380) {
						_this.unitBoxHeight = className.length * 35
					} else {
						_this.unitBoxHeight = 380
					}
					setTimeout(() => {
						_this.isShowBarChart = true
					})
					setTimeout(() => {
						var unitSituationeChart = echarts.init(document.getElementById('unitSituation'));
						if(this.buttonActive == 0) {
							unitSituationeChart.setOption({
								grid: {
									left: '2%',
									right: '4%',
									bottom: '5%',
									top: '5',
									containLabel: true
								},
								tooltip: {
									trigger: 'axis',
									axisPointer: {
										type: 'shadow'
									}
								},
								legend: {
									data:[],
									icon:'circle',
									bottom:10,
									textStyle:{
										color:'#ffffff'
									}
								},
								xAxis: {
									type: 'value',
									axisLine: {
										show: false
									},
									axisLabel: {
										show: false
									},
									splitLine: {
										show: false,
									},
								},
								yAxis: {
									type: 'category',
									inverse: true,
									splitLine: {
										show: false,
									},
									data: className,
									axisLabel: {
										textStyle: {
											color: '#fff',
											fontSize: "0.7rem",
										},
										formatter : function(params){
											var newParamsName = "";// 最终拼接成的字符串
											var paramsNameNumber = params.length;// 实际标签的个数
											var provideNumber = 8;// 每行能显示的字的个数
											var rowNumber = Math.ceil(paramsNameNumber / provideNumber);// 换行的话，需要显示几行，向上取整
											if (paramsNameNumber > provideNumber) {
												for (var p = 0; p < rowNumber; p++) {
													var tempStr = "";// 表示每一次截取的字符串
													var start = p * provideNumber;// 开始截取的位置
													var end = start + provideNumber;// 结束截取的位置
													// 此处特殊处理最后一行的索引值
													if (p == rowNumber - 1) {
														// 最后一次不换行
														tempStr = params.substring(start, paramsNameNumber);
													} else {
														// 每一次拼接字符串并换行
														tempStr = params.substring(start, end) + "\n";
													}
													newParamsName += tempStr;// 最终拼成的字符串
												}
											} else {
												// 将旧标签的值赋给新标签
												newParamsName = params;
											}
											//将最终的字符串返回
											return newParamsName
										}
									},
									splitLine: {
										show: false
									},
									axisTick: {
										show: true
									},
									axisLine: {
										show: true
									},
								},
								series: [{
									name: _this.leftBottomBarTitleOne,
									type: 'bar',
									barWidth: 13,
									data: cityData1,
									label: {
										color: '#fff',
										show: true
									},
									itemStyle: {
										emphasis: {
											barBorderRadius: [0, 20, 20, 0],
										},
										normal: {
											barBorderRadius: [0, 20, 20, 0],
											color: new echarts.graphic.LinearGradient(0,0,1,0,[{
												offset: 0,
												color: '#00b6b2', // 0% 处的颜色
											},{
												offset: 1,
												color: '#00b6b2', // 100% 处的颜色
											}],false)
										}
									},
								},{
									name: _this.leftBottomBarTitleTwo,
									textStyle: {
										color: '#4f9efe',
										fontStyle: 'normal',
										fontWeight: 'normal',
										fontFamily: 'sans-serif',
										fontSize: 14,
									},
									label: {
										show: true,
										position: 'right',
										color: '#fff',
										fontFamily: 'Bebas',
									},
									type: 'bar',
									barWidth: 13,
									barGap: '-100%',
									itemStyle: {
										color: 'rgba(0,0,0,0)',
										borderColor: '#fff',
										padding: 0,
										emphasis: {
											barBorderRadius: [0,15,15,0],
										},
										normal: {
											barBorderRadius: [0,15,15,0],
											color: new echarts.graphic.LinearGradient(0,0,1,0,[{
												offset: 0,
												color: '#4f9efe', // 0% 处的颜色
											},{
												offset: 1,
												color: '#4f9efe', // 100% 处的颜色
											}],false)
										},
									},
									z: -10,
									data: cityData2,
								}],
							})
						} else {
							unitSituationeChart.setOption({
								grid: {
									left: '2%',
									right: '4%',
									bottom: '5%',
									top: '5',
									containLabel: true
								},
								tooltip: {
									trigger: 'axis',
									axisPointer: {
										type: 'shadow'
									},
									formatter: function(params) {
										if(params[0].name == "企业志愿消防队员、微型消防站队员、易燃易爆危险品从业人员") {
											return '<div>'
												+'<div style="font-weight: bold;">企业志愿消防队员</div>'
												+'<div style="display: flex;justify-content: space-between;">'
													+'<div>人数：</div><div>' + _this.unitFirer + '</div>'
												+'</div>'
											+'</div><div>'
												+'<div style="font-weight: bold;margin-top:15px;">微型消防站队员</div>'
												+'<div style="display: flex;justify-content: space-between;">'
													+'<div>人数：</div><div>' + _this.stationFirer + '</div>'
												+'</div>'
											+'</div>'
										} else {
											return '<div><div>' + params[0].name + '</div>'
													+'<div style="display: flex;justify-content: space-between;"><div>人数：</div><div>' + params[0].data + '</div></div></div>'
										}
									}
								},
								legend: {
									data:[],
									icon:'circle',
									bottom:10,
									textStyle:{
										color:'#ffffff'
									}
								},
								xAxis: {
									type: 'value',
									axisLine: {
										show: false
									},
									axisLabel: {
										show: false
									},
									splitLine: {
										show: false,
									},
								},
								yAxis: {
									type: 'category',
									inverse: true,
									// splitNumber: 5,
									axisLabel: {
										show: true,
										textStyle: {
											color: '#fff',
											fontSize:"0.7rem"
										},
										formatter : function(params){
											var newParamsName = "";// 最终拼接成的字符串
											var paramsNameNumber = params.length;// 实际标签的个数
											var provideNumber = 8;// 每行能显示的字的个数
											var rowNumber = Math.ceil(paramsNameNumber / provideNumber);// 换行的话，需要显示几行，向上取整
											if (paramsNameNumber > provideNumber) {
												for (var p = 0; p < rowNumber; p++) {
													var tempStr = "";// 表示每一次截取的字符串
													var start = p * provideNumber;// 开始截取的位置
													var end = start + provideNumber;// 结束截取的位置
													// 此处特殊处理最后一行的索引值
													if (p == rowNumber - 1) {
														// 最后一次不换行
														tempStr = params.substring(start, paramsNameNumber);
													} else {
														// 每一次拼接字符串并换行
														tempStr = params.substring(start, end) + "\n";
													}
													newParamsName += tempStr;// 最终拼成的字符串
												}
											} else {
												// 将旧标签的值赋给新标签
												newParamsName = params;
											}
											//将最终的字符串返回
											return newParamsName
										}
									},
									splitLine: {
										show: false
									},
									axisTick: {
										show: true
									},
									axisLine: {
										show: true
									},
									data: className,
								},
								series: [
									{
										name: _this.leftBottomBarTitleOne,
										type: 'bar',
										barWidth: 13,
										stack: 'total',
										color:'#00b6b2',
										label: {
											show: true
										},
										emphasis: {
											focus: 'series'
										},
										data: cityData1
									}
								]
							})
						}
					})
				}
			})
		},
		// 地图
		getViewMapTable(e){
			var mapeChart = echarts.init(document.getElementById('MabTable'));
			cityMapFun(mapeChart,this,e)
		},
		// 地图上部本级数据
		getNextUnitsAndCertificatesResponse(paramsObj) {
			var params = {
				provinceID: 1919
			}
			if(paramsObj) {
				params.cityID = paramsObj.CityID
				params.regionID = paramsObj.RegionID
				params.townID = paramsObj.TownID
			}
			var getDataApi = ''
			if(this.buttonActive == 3) {
				getDataApi = getCurrentLevelCertificateHoldingByID
			} else if(this.buttonActive == 2) {
				getDataApi = getCurrentLevelParticipationByID
			} else if(this.buttonActive == 1) {
				getDataApi = getUnitAndRegistrationCountRateStatisticsByID
			} else {
				getDataApi = getTrainingExaminationCertificateByID
			}
			getDataApi(params).then(res => {
				var result = res.data
				if(result.Success) {
					if(this.buttonActive == 3) {
						this.files.SocialUnit = result.Response.NumberOfPeopleWithCertificates
						this.files.learningSocialUnit = result.Response.CurrentNumberOfCertificateHolders
						this.files.btainCertificate = result.Response.NumberOfUnitsToBeSupervised
						this.files.supervisionUnit = this.redefinitionDecimalFun(result.Response.RateToBeSupervised)
					} else if(this.buttonActive == 2) {
						this.files.SocialUnit = result.Response.NumberOfTrainingUnits
						this.files.learningSocialUnit = this.redefinitionDecimalFun(result.Response.UnitParticipationRate)
						this.files.btainCertificate = result.Response.NumberOfParticipants
						this.files.supervisionUnit = this.redefinitionDecimalFun(result.Response.PersonnelParticipationRate)
					} else if(this.buttonActive == 1) {
						this.files.SocialUnit = result.Response.RegistrationUnitsCount
						this.files.learningSocialUnit = this.redefinitionDecimalFun(result.Response.RegistrationUnitsRate)
						this.files.btainCertificate = result.Response.RegistrationPeopleCount
						this.files.supervisionUnit = this.redefinitionDecimalFun(result.Response.RegistrationPeopleRate)
					} else {
						this.files.SocialUnit = result.Response.TraineeCount
						this.files.learningSocialUnit = result.Response.ApplicantCount
						this.files.btainCertificate = result.Response.ParticipantCount
						this.files.supervisionUnit = result.Response.CertificatesCount
					}
				}
			})
		},
		// 获取地图上部滑上浮窗数据
		getMapTopWinFun(paramsObj) {
			var params = {
				provinceID: 1919
			}
			if(paramsObj) {
				params.cityID = paramsObj.CityID
				params.regionID = paramsObj.RegionID
				params.townID = paramsObj.TownID
			}
			getStudentLearnRecords(params).then((res) => {
				var result = res.data
				if(result.Success) {
					this.floatPostWinArr = result.Response.Station
					this.floatIndustrytWinArr = result.Response.Trade
				}
			})
		},
		// 地图下方按钮切换
		buttonChangeFun(val) {
			// if(val != this.buttonActive) {
				var _this = this
				_this.isShowNavBar = 10000000
				this.isHiddenMap = false
				this.buttonActive = val
				var buttonHtml = document.getElementById("buttonBox").childNodes
				for(var a = 0; a < buttonHtml.length; a++) {
					if(a == this.buttonActive) {
						buttonHtml[a].firstChild.classList.add('buttonActive')
					} else {
						buttonHtml[a].firstChild.classList.remove('buttonActive')
					}
				}
				var buttonHtmlCopy = document.getElementById("buttonBoxCopy").childNodes
				for(var b = 0; b < buttonHtmlCopy.length; b++) {
					if(b == this.buttonActive) {
						buttonHtmlCopy[b].firstChild.classList.add('buttonActive')
					} else {
						buttonHtmlCopy[b].firstChild.classList.remove('buttonActive')
					}
				}
				// 跟新title
				this.updateLeftTopTitle(this.buttonActive)
				this.updateLeftBottomTitle(this.buttonActive)
				this.updateMiddleTopTitle(this.buttonActive)
				this.updateMiddleMapTitle(this.buttonActive)
				this.updateRightTopTitle(this.buttonActive)
				// 跟新地图颜色
				this.updateMapColor(this.buttonActive)
				// 跟新页面显示模块
				this.baseData.isShowPie = true
				this.baseData.isShowTable2 = true
				this.baseData.isShowTable1 = false
				// api请求
				setTimeout(() => {
					_this.isHiddenMap = true
					_this.unifiedRequestAPIfun();
				})
			// }
		},
		// 更新左上部柱状图title
		updateLeftTopTitle(val) {
			if(val == 1) {
				this.leftTopeLineTitle = '每日报考人数'
			} else if(val == 2) {
				this.leftTopeLineTitle = '每日参训人数'
			} else if(val == 3) {
				this.leftTopeLineTitle = '每日取证人数'
			}
		},
		// 更新左下部柱状图title
		updateLeftBottomTitle(val) {
			if(val == 0) {
				this.leftBottomBarTitleOne = '待监管单位数'
				this.leftBottomBarTitleTwo = '单位数量'
			} else if(val == 1) {
				this.leftBottomBarTitleOne = '报考人数'
				this.leftBottomBarTitleTwo = '应训人数'
			} else if(val == 2) {
				this.leftBottomBarTitleOne = '参训人数'
				this.leftBottomBarTitleTwo = '应训人数'
			} else if(val == 3) {
				this.leftBottomBarTitleOne = '持证数'
				this.leftBottomBarTitleTwo = '应训人数'
			}
		},
		// 更新中上部柱状图title
		updateMiddleTopTitle(val) {
			if(val == 0) {
				this.middleTopPieceTitle = {
					PieceTitleOne: '应训人数（人）',
					PieceTitleTwo: '报考人数（人）',
					PieceTitleThree: '参训人数（人）',
					PieceTitleFour: '持证数量',
				}
			} else if(val == 1) {
				this.middleTopPieceTitle = {
					PieceTitleOne: '报考单位数（家）',
					PieceTitleTwo: '单位报考率',
					PieceTitleThree: '报考人数（人）',
					PieceTitleFour: '人员报考率',
				}
			} else if(val == 2) {
				this.middleTopPieceTitle = {
					PieceTitleOne: '参训单位数（家）',
					PieceTitleTwo: '单位参训率',
					PieceTitleThree: '参训人数（人）',
					PieceTitleFour: '人员参训率',
				}
			} else if(val == 3) {
				this.middleTopPieceTitle = {
					PieceTitleOne: '应持证人数',
					PieceTitleTwo: '当前持证数',
					PieceTitleThree: '待监管单位数',
					PieceTitleFour: '待监管率',
				}
			}
		},
		// 更新中部地图title
		updateMiddleMapTitle(val) {
			if(val == 0) {
				this.middleMapTitle = {
					mapTitleOne: '人员参训率<30%',
					mapTitleTwo: '30%≤人员参训率≤70%',
					mapTitleThree: '人员参训率>70%',
				}
			} else if(val == 1) {
				this.middleMapTitle = {
					mapTitleOne: '人员报考率<30%',
					mapTitleTwo: '30%≤人员报考率≤70%',
					mapTitleThree: '人员报考率>70%',
				}
			} else if(val == 2) {
				this.middleMapTitle = {
					mapTitleOne: '单位参训率<30%',
					mapTitleTwo: '30%≤单位参训率≤70%',
					mapTitleThree: '单位参训率>70%',
				}
			} else if(val == 3) {
				this.middleMapTitle = {
					mapTitleOne: '待监管率<30%',
					mapTitleTwo: '30%≤待监管率≤70%',
					mapTitleThree: '待监管率>70%',
				}
			}
		},
		// 更新右上部柱状图title
		updateRightTopTitle(val) {
			if(val == 0) {
				this.rightTopPieTitle = '机构培训概况'
			} else if(val == 1) {
				this.rightTopPieTitle = '报考人数概况'
			} else if(val == 2) {
				this.rightTopPieTitle = '参训人数概况'
			} else if(val == 3) {
				this.rightTopPieTitle = '持证数概况'
			}
		},
		// 跟新地图颜色
		updateMapColor(val) {
			if(val == 3) {
				this.mapColor1 = '#ca505b'
				this.mapColor2 = '#13b5b1'
			} else {
				this.mapColor1 = '#13b5b1'
				this.mapColor2 = '#ca505b'
			}
		},
		// 饼状图
		getViewPieTable(paramsObj){
			var that = this
			let index = 0;
			var colorList = ['#DF3AB9', '#18C79C', '#8555F9', '#F09228','#39B3FF','#47E0E0','#7891D9','#83D978','#C7A530','#FF8439','#39B3FF','#DF3AB9', '#18C79C', '#8555F9', '#F09228','#39B3FF','#47E0E0','#7891D9','#83D978','#C7A530','#FF8439','#39B3FF'];
			var data = [];
			var params = {
				provinceID: 1919
			}
			if(paramsObj) {
				params.cityID = paramsObj.CityID
				params.regionID = paramsObj.RegionID
				params.townID = paramsObj.TownID
			}
			var getDataApi = ''
			if(this.buttonActive == 3) {
				getDataApi = getCertificateCountByID
			} else if(this.buttonActive == 2) {
				getDataApi = getTrainingCountByID
			} else if(this.buttonActive == 1) {
				getDataApi = getUnitAndRegistrationCountRateStatistics
			} else {
				getDataApi =getAgencyInfoPass
			}
				// getDataApi =getProportionCertificateHoldersByID  //原版接口
			getDataApi(params).then(res => {
				var result = res.data
				if(result.Success) {
					var dataCount = 0
					if(this.buttonActive == 3 || this.buttonActive == 2) {
						result.Response.Data.forEach((item) => {
							dataCount += parseInt(item.Value)
						})
						data = result.Response.Data
					} else if(this.buttonActive == 1) {
						result.Response.forEach((item) => {
							dataCount += item.RegistrationPeopleCount
						})
						data = result.Response
					} else {
						result.Response.forEach((item) => {
							dataCount += item.PassCount
						})
						data = result.Response
					}
				}
				var pieTableChart = echarts.init(document.getElementById('pieTable'));
				function autoPlay() {
					pieTableChart.dispatchAction({
						type: 'hideTip',
						seriesIndex: 0,
						dataIndex: index,
					});
					// 显示提示框
					pieTableChart.dispatchAction({
						type: 'showTip',
						seriesIndex: 0,
						dataIndex: index,
					});
					// 取消高亮指定的数据图形
					// pieTableChart.dispatchAction({
					// 	type: 'downplay',
					// 	seriesIndex: 0,
					// 	dataIndex: index === 0 ? 5 : index - 1,
					// });
					pieTableChart.dispatchAction({
						type: 'highlight',
						seriesIndex: 0,
						dataIndex: index,
					});
				}
				autoPlay();
				var newData = [];
				// 加阴影效果
				for (var i = 0; i < data.length; i++) {
					if(this.buttonActive == 1) {
						if(data[i].RegistrationPeopleCount > 0) {
							newData.push({
								value: data[i].RegistrationPeopleCount,
								name: data[i].OriginalName + " " + this.redefinitionDecimalFun(data[i].RegistrationPeopleCount / dataCount),
								showName: data[i].OriginalName,
								itemStyle: {
									normal: {
										shadowBlur: 15,
										shadowColor: colorList[i],
									},
								},
							});
						}
					} else if(this.buttonActive == 2 || this.buttonActive == 3) {
						if(data[i].Value) {
							newData.push({
								value: data[i].Value,
								name: data[i].Name + " " + this.redefinitionDecimalFun(data[i].Value / dataCount),
								showName: data[i].Name,
								itemStyle: {
									normal: {
										shadowBlur: 15,
										shadowColor: colorList[i],
									},
								},
							});
						}
					} else {
						if(data[i].PassCount) {
							newData.push({
								value: data[i].PassCount,
								name: data[i].Name + " " + this.redefinitionDecimalFun(data[i].PassCount / dataCount),
								showName: data[i].Name,
								itemStyle: {
									normal: {
										shadowBlur: 15,
										shadowColor: colorList[i],
									},
								},
							});
						}
					}
				}
				var showCityName = ''
            	var showCityData = ''
				pieTableChart.setOption({
					backgroundColor: 'rgba(6, 23, 64, 0.8)',
					// title: {
					// 	text: '证书数量',
					// 	left: '26%',
					// 	top: '45%',
					// 	textStyle: {
					// 		fontSize: 12,
					// 		color: '#fff',
					// 	},
					// },
					tooltip: {
						trigger: 'item',
						formatter: function (params) {
							if(that.buttonActive == 1) {
								if(showCityName != params.data.showName) {
									for(var a = 0; a < data.length; a++) {
										if(params.data.showName == data[a].OriginalName) {
											showCityName = data[a].OriginalName
											showCityData = data[a]
											return
										}
									}
								}
							} else {
								if(showCityName != params.data.showName) {
									for(var a = 0; a < data.length; a++) {
										if(params.data.showName == data[a].Name) {
											showCityName = data[a].Name
											showCityData = data[a]
											return
										}
									}
								}
							}
							if(that.buttonActive == 0) {
								return '<div style="line-height: 24px;text-align: start;color: #000">培训机构：' + showCityData.Name + '</div>'
									+'<div style="line-height: 24px;text-align: start;color: #000">培训人数：' + showCityData.Count + '</div>'
									// +'<div style="line-height: 24px;text-align: start;color: #000">通过率：' + ((showCityData.PassCount / showCityData.Count) * 100 ).toFixed(2) + '%</div>'
									+'<div style="line-height: 24px;text-align: start;color: #000">通过率：' + (that.redefinitionDecimalFun(showCityData.PassCount / showCityData.Count) ) + '</div>'
							} else if(that.buttonActive == 1) {
								return '<div style="line-height: 24px;text-align: start;color: #000">' + showCityData.OriginalName + '报考人数：' + showCityData.RegistrationPeopleCount + '</div>'
							} else if(that.buttonActive == 2) {
								return '<div style="line-height: 24px;text-align: start;color: #000">' + showCityData.Name + '参训人数：' + showCityData.Value + '</div>'
							} else {
								return '<div style="line-height: 24px;text-align: start;color: #000">' + showCityData.Name + '持证数量：' + showCityData.Value + '</div>'
							}
						}
					},
					graphic:{
						type:"text",
						left:"1%",
						top:"2%",
						style:{
							text:this.rightTopPieTitle,
							textAlign:"center",
							fill:"#fff",
							fontSize:13,
							fontWeight:300
						}
					},
					legend: {
						type: 'scroll',
						orient: 'vertical',
						left: '55%',
						top: 'center',
						itemGap: 10,
						selectedMode: true,
						icon: 'pin',
						textStyle: {
							color: '#fff',
							rich: {
								a: {
									color: '#666666',
									fontSize: 14,
									fontWeight: 500,
									width: 30,
									padding: [0, 0, 25, 2],
								},
								b0: {
									color: '#666666',
									fontSize: 14,
									fontWeight: 500,
									height: 20,
									padding: [0, 30, 8, 4],
								},
								b1: {
									fontSize: 20,
									padding: [0, 30, 0, 40],
								},
								b2: {
									fontSize: 20,
									padding: [0, 30, 0, 40],
								},
								b3: {
									fontSize: 20,
									padding: [0, 30, 0, 40],
								}
							}
						}
					},
					series: [{
						type: 'pie',
						center: ['50%', '50%'],
						radius: ['38%', '55%'],
						right: '40%',
						clockwise: true,
						avoidLabelOverlap: true,
						hoverOffset: 2,
						tooltip: {
							trigger: 'item',
							backgroundColor: '#f3f3f3',
							textStyle: {
								color: '#666666',
								fontSize: 14,
								fontWeight: 500,
							},
							padding: [10, 20],
							extraCssText: 'box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);',
						},
						emphasis: {
							itemStyle: {
								borderColor: '#f3f3f3',
								borderWidth: 5,
							},
						},
						//启用鼠标放上去放大效果，这个挺好的
						itemStyle: {
							normal: {
								color: function (params) {
									return colorList[params.dataIndex];
								},
							},
						},
						label: {
							show: false,
							position: 'outside',
							formatter: '{a|{b}：{d}%}\n{hr|}',
							rich: {
								hr: {
									backgroundColor: 't',
									borderRadius: 1,
									width: 1,
									height: 1,
									padding: [1, 1, 0, -4],
								},
								a: {
									padding: [-15, 7, -10, 7],
								},
							},
						},
						labelLine: {
							normal: {
								length: 10,
								length2: 15,
								lineStyle: {
									width: 1,
								},
							},
						},
						data: newData,
					}]
				});
			})
			// 自动播放函数
		},
		// 获取正确率排名列表
		getViewKnowRateData(paramsObj){
			this.knowTableData = []
			var params = {
				provinceID: 1919
			}
			if(paramsObj) {
				params.cityID = paramsObj.CityID
				params.regionID = paramsObj.RegionID
				params.townID = paramsObj.TownID
			}
			getMaterialExamPaperRightRatio(params).then(res => {
				var result = res.data
				if(result.Success) {
					this.knowTableData = result.Response
					var rateSun = 0
					result.Response.forEach(item => {
						rateSun += item.RightRatio
					})
					this.tableAverage = ((rateSun / result.Response.length) * 100).toFixed(2) + '%'
				}
			})
		},
		// 获取右下下辖区域概况
		getViewTbaleData2(paramsObj){
			this.tbaleData2 = []
			var params = {
				provinceID: 1919
			}
			if(paramsObj) {
				params.cityID = paramsObj.CityID
				params.regionID = paramsObj.RegionID
				params.townID = paramsObj.TownID
			}
			if(paramsObj.RegionID > 0) {
				this.rightTableTitle = '下辖街道概况'
				this.rightTableOneTitle = '街道'
			} else if(paramsObj.CityID > 0) {
				this.rightTableTitle = '下辖区域概况'
				this.rightTableOneTitle = '区域'
			} else {
				this.rightTableTitle = '下辖区域概况'
				this.rightTableOneTitle = '区域'
			}
			var getDataApi = ''
			if(this.buttonActive == 3) {
				getDataApi = getCertificateHoldingUnderTheJurisdiction
			} else if(this.buttonActive == 2) {
				getDataApi = getParticipatingJurisdictions
			} else if(this.buttonActive == 1) {
				getDataApi = getUnitAndRegistrationCountRateStatistics
			} else {
				getDataApi = getTrainingExaminationCertificateStatistics
			}
			getDataApi(params).then(res => {
				var result = res.data
				if(result.Success) {
					this.tbaleData2 = result.Response
				}
			})
		},
		// 获取乡镇右部单位明细概况
		getViewTbaleData1(paramsObj){
			this.tbaleData1 = []
			var params = {
				provinceID: 1919,
				pageIndex: 1,
				pageSize: 100,
			}
			if(paramsObj) {
				params.cityID = paramsObj.CityID
				params.regionID = paramsObj.RegionID
				params.townID = paramsObj.TownID
			}
			getParticipatingJurisdictionsRegion(params).then(res => {
				var result = res.data
				if(result.Success && result.Response) {
					this.tbaleData1 = result.Response.Data
				}
			})
		},
		// 获取社会单位明细
		getViewTbaleData3(paramsObj){
			this.tbaleData3 = []
			var params = {
				provinceID: 1919,
                pageIndex: 1,
                pageSize: 100,
            }
			if(paramsObj) {
				params.cityID = paramsObj.CityID
				params.regionID = paramsObj.RegionID
				params.townID = paramsObj.TownID
			}
			getSocialUnitListPage(params).then(res => {
				var result = res.data
				if(result.Success) {
					this.tbaleData3 = result.Response.Data
				}
			})
		},
		// 地图/地图滑上浮窗数据
		getSlipupMapDataFun(paramsObj,cityName) {
			this.slipupMapArr = []
			var that = this
			var params = {
				provinceID: 1919,
				jsonFileName: cityName ? cityName.oneselfID : "44",
			}
			if(paramsObj) {
				params.cityID = paramsObj.CityID
				params.regionID = paramsObj.RegionID
				params.townID = paramsObj.TownID
			}
			var getDataApi = ''
			if(this.buttonActive == 3) {
				getDataApi = getMapOfCertificateHolding
			} else if(this.buttonActive == 2) {
				getDataApi = getTrainingMap
			} else if(this.buttonActive == 1) {
				getDataApi = getMapRegistrationRateResponseStatistics
			} else {
				getDataApi = getUnitAndSupervisionResponseStatistics
			}
				// getDataApi = getUnitsAndCertificatesSingleResponseByID //原版接口
			getDataApi(params).then(res => {
				var result = res.data
				if(result.Success) {
					if(this.buttonActive == 3) {
						result.Response.StatisticsData.forEach((item,index) => {
							item.value = item.RateToBeSupervised * 100
							// item.name = item.Name
						});
					} else if(this.buttonActive == 2) {
						result.Response.StatisticsData.forEach((item,index) => {
							item.value = item.UnitParticipationRate * 100
							// item.name = item.Name
						});
					} else if(this.buttonActive == 1) {
						result.Response.StatisticsData.forEach((item,index) => {
							if(item.RegistrationPeopleRate > 1) {
								item.value = 100
								item.RegistrationPeopleRate = 1
							} else {
								item.value = item.RegistrationPeopleRate * 100
							}
							// item.name = item.Name
						});
					} else {
						result.Response.StatisticsData.forEach((item,index) => {
							if(item.TraineeCount > 0) {
								item.value = item.ParticipationRate * 100
							} else {
								item.value = 0
							}
							// item.name = item.Name
						});
					}
					var newObj = {
						name: cityName ? cityName.oneselfName : '',
						subData: result.Response.StatisticsData,
					}
					this.GeoJson = result.Response.GeoJson,
					this.slipupMapArr.push(newObj)
					// 地图
					if(!cityName) {
						var userData = JSON.parse(window.localStorage.user)
						if(userData.RegionID > 0) {
							that.getAreaData(userData.CityID,2,userData.RegionID)
						} else if(userData.CityID > 0) {
							that.getAreaData(1919,1,userData.CityID)
						} else {
							that.getViewMapTable({oneselfName: "广东省",oneselfID: 44})
						}
					} else {
						that.getViewMapTable(cityName)
					}
				} else {
					// 地图
					if(!cityName) {
						var userData = JSON.parse(window.localStorage.user)
						if(userData.RegionID > 0) {
							that.getAreaData(userData.CityID,2,userData.RegionID)
						} else if(userData.CityID > 0) {
							that.getAreaData(1919,1,userData.CityID)
						} else {
							that.getMapJsonDataFun({oneselfName: "广东省",oneselfID: 44})
						}
					} else {
						that.getMapJsonDataFun(cityName)
					}
				}
			})
		},
		// 统一调用接口事件
		unifiedRequestAPIfun(paramsVal,cityName) {
			var that = this
			var userData = JSON.parse(window.localStorage.user)
			var paramsObj = {
				provinceID: 1919,
				CityID: null,
				RegionID: null,
				TownID: null,
			}
			if(paramsVal) {
				paramsObj.CityID = paramsVal.CityID
			} else if(userData.CityID > 0) {
				paramsObj.CityID = userData.CityID
			}
			if(paramsVal) {
				paramsObj.RegionID = paramsVal.RegionID
			} else if(userData.RegionID > 0) {
				paramsObj.RegionID = userData.RegionID
			}
			if(paramsVal) {
				paramsObj.TownID = paramsVal.TownID
			} else if(userData.TownID > 0) {
				paramsObj.TownID = userData.TownID
			}
			if(!cityName) {
				if(userData.AreaCode) {
					var cityName = {
						oneselfID: userData.AreaCode,
						oneselfName: "广东省"
					}
				}
			}
			// 地图/地图滑上浮窗数据
			that.getSlipupMapDataFun(paramsObj,cityName)
			// 左上角线性图
			if(that.buttonActive != 0) {
				that.getViewlineTable(paramsObj)
			}
			// 左上角正确率排名
			if(that.buttonActive == 0) {
				that.getViewKnowRateData(paramsObj)
			}
			// 左下角柱状图
			that.getViewunitSituation(paramsObj)
			// 地图上部本级数据
			that.getNextUnitsAndCertificatesResponse(paramsObj)
			// 获取地图上部滑上浮窗数据
			// that.getMapTopWinFun(paramsObj)
			// 右上角饼状图
			if(that.baseData.isShowPie) {
				that.getViewPieTable(paramsObj)
			}
			// 获取右下下辖区域概况
			if(that.baseData.isShowTable2) {
				that.getViewTbaleData2(paramsObj)
			}
			// 获取乡镇右部单位明细概况
			if(that.baseData.isShowTable1) {
				that.getViewTbaleData1(paramsObj)
			}
			// 获取社会单位明细
			if(that.baseData.isShowTable3) {
				that.getViewTbaleData3(paramsObj)
			}
		},
		// 跳转社会单位明细
		socialUnitFun() {
			this.$router.replace({path:'/LicensedSupervision/SocialUnit',query:{userDataParams:JSON.stringify(this.userDataParams)}})
		},
		// 跳转待监管单位
		supervisionUnitFun() {
			this.$router.replace({path:'/LicensedSupervision/ToBePunishBill',query:{userDataParams:JSON.stringify(this.userDataParams)}})
		},
		getAreaData(parentId,val,nowID) {
			var that = this
			var params = {
				parentId: parentId,
				level: val,
			}
			getAreaDictionaryList(params).then(res => {
				var result = res.data
				if(result.Success) {
					for(var i = 0; i < result.Response.length; i++) {
						if(result.Response[i].ID == nowID) {
							var nowAreaName =  result.Response[i].Name
							that.getViewMapTable(nowAreaName)
						}
					}
				}
			})
		},
		// 获取城市ID
		getCityID(name) {
			if(this.guangdongData && this.guangdongData.length == 0) {
				this.guangdongData = JSON.parse(sessionStorage.getItem("guangdong"))
			}
			if(name) {
				for(var i = 0; i < this.guangdongData.length; i++) {
					if(this.guangdongData[i].Name == name) {
						return this.guangdongData[i].ID
					}
				}
			}
		},
		// 单独获取地图json
		getMapJsonDataFun(cityName) {
			var params = {
				fileName: cityName.oneselfID
			}
			getJsonFile(params).then((res) => {
				var result = res.data
				if(result.Success) {
					this.GeoJson = result.Response
					this.getViewMapTable(cityName.oneselfName)
				}
			})
		},
		// 表格样式修改
		knowRateStyle({row,column,rowIndex,columnIndex}) {
			return "color: #fff;"
		},
		knowRateRowStyle({row,column,rowIndex,columnIndex}) {

		},
		// 截取数字
		formatRightRatio(val) {
			if(val > 0) {
				return (val * 100).toFixed(2) + '%'
			} else {
				return 0
			}
		},
		redefinitionDecimalFun(val) {
			if(val && val > 0) {
				if(val < 0.01) {
					return 1 + '%'
				} else {
					// 不四舍五入向下取整
					var num = (Math.floor(val * 100) / 100) * 100
					// 四舍五入
					// var num = (Math.round(val * 100) / 100) * 100
					if(num > 100) {
						var resultNum = 100
					} else {
						if((num + '').length > 5) {
							var resultNum = num.toString().slice(0,2)
						} else {
							var resultNum = num
						}
					}
					return resultNum + '%'
				}
			} else {
				return 0
			}
		}
	},
	beforeMount() {
		var pageHeight = document.getElementById('app').offsetHeight - 60 - 36 - 20
		this.tableHeight1 = (pageHeight * 0.45) - 32
		this.tableHeight2 = (pageHeight * 0.5) - 32
		this.pageHeight = pageHeight
		this.mapHeight = pageHeight - 210
	},
	mounted() {
		var _this = this
		var curTime = new Date();
		if (window.localStorage.TokenExpire) {
			var expiretime = new Date(Date.parse(window.localStorage.TokenExpire));
			if (curTime >= expiretime) {
				this.$router.push("/login");
				return
			}
		} else {
			this.$router.push("/login");
			return
		}
		var userData = JSON.parse(window.localStorage.user)
		if(userData.TownID > 0) {
			this.baseData.isShowTable1 = true
			this.baseData.isShowTable2 = false
			this.baseData.isShowPie = false
		}
		// setTimeout(() => {
		// 	_this.unifiedRequestAPIfun()
		// })
	},
};
</script>

<style lang="stylus" scoped>
	.boxWelcomeTitle {
		display: flex;
		justify-content: space-between;
		margin-top: -20px;
		background: rgba(6, 23, 64, 1)!important;
		font-size: 20px;
		color: #39B3FF;
		line-height: 40px;
	}
	.tableTopBox {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: rgba(6, 23, 64, 0.7)!important;
		padding-top: 4px;
		height: 29px;
	}
	.tableTitle {
		color #fff;
		font-size: 14px;
		padding-left: 3px;
		cursor: pointer;
	}
	.tableTopBox img{
		width: 30px;
    	height: 30px;
		padding-right: 3px;
		cursor: pointer;
	}
	.tableClass>>>.el-table__header-wrapper table thead tr th {
		background: rgba(6, 23, 64, 0.7)!important;
		color #fff;
	}
	.tableClass>>>.el-table__body tbody .el-table__row {
		background: rgba(6, 23, 64, 0.7)!important;
	}
	.tableClass>>>.el-table__body tbody .el-table__row td .cell .el-tag {
		background-color: rgba(6, 23, 64, 0.7)!important;
		border-color: #409EFF!important;
	}
	.tableClass>>>td, .tableClass>>>th.is-leaf {
		border-bottom: 1px solid rgba(6, 23, 64, 0.7)!important;
	}
	.tableClass {
		background-color: rgba(6, 23, 64, 0.7)!important;
	}
	.tableClass>>>.el-table__body-wrapper,.tableClass>>>.is-scrolling-none {
		// padding-right: 20px;
	}
	.tableClass>>>.el-table__body-wrapper::-webkit-scrollbar {
		width: 0px;
	}
	.knowRateClass>>>.el-table__header-wrapper table thead tr th {
		background: rgba(6, 23, 64, 0.7)!important;
		color #fff;
	}
	.knowRateClass>>>.el-table__body tbody .el-table__row {
		background: rgba(6, 23, 64, 0.7)!important;
	}
	.knowRateClass>>>.el-table__body tbody .el-table__row td .cell .tagWarning {
		background-color: #ffc760!important;
		border-color: #409EFF!important;
		color: #fff!important;
	}
	.knowRateClass>>>.el-table__body tbody .el-table__row td .cell .tagDanger {
		background-color: #d62c2c!important;
		border-color: #409EFF!important;
		color: #fff!important;
	}
	.knowRateClass>>>.el-table__body tbody .el-table__row td .cell .tagBlue {
		background-color: #00b6b2!important;
		border-color: #409EFF!important;
		color: #fff!important;
	}
	.knowRateClass>>>td, .knowRateClass>>>th.is-leaf {
		border-bottom: 1px solid rgba(6, 23, 64, 0.7)!important;
	}
	.knowRateClass {
		background-color: rgba(6, 23, 64, 0.7)!important;
	}
	.knowRateClass>>>.el-table__body-wrapper::-webkit-scrollbar {
		width: 0px;
	}
	// 鼠标滑上表格行颜色的变化
	.knowRateClass>>>.el-table__body tr:hover>td {
		background-color: #eee!important;
		color: rgba(6, 23, 64, 0.7)!important;
	}
	.centerViews {
		display: flex;
    	justify-content: space-around;
    	align-items: center;
		height: 140px;
	}
	.viewsBox {
		width: 250px;
    	height: 100px;
		background: rgba(6, 23, 64, 0.7);
		display: flex;
		justify-content: space-around;
		border: 1px dashed #47e0e0
	}
	.viewsBox div {
		display: flex;
		flex-direction: column;
		line-height: 36px;
		justify-content: center;
	}
	.boxTitle {
		font-size: 14px;
		color: #fff;
	}
	.boxViewNum {
		font-size: 26px;
		font-weight: bold;
		color: #47E0E0;
	}
	#knowRate {
		margin-top: 18px;
		border: 1px solid #47e0e0;
	}
	#lineTable {
		margin-top: 18px;
		border: 1px solid #47e0e0;
	}

	#pieTable,#trainingSituation {
		margin-top: 18px;
		border: 1px solid #47e0e0;
	}

	#StyloliticTable,#sortTable,#unitParentBox {
		border-right: 1px solid #47e0e0;
		border-left: 1px solid #47e0e0;
		border-bottom: 1px solid #47e0e0;
	}
	.table1 {
		border-top: 1px solid #47e0e0;
	}
	.buttonBox {
		margin-top: 20px;
		display: flex;
		justify-content: center;
		position: absolute;
		bottom: 0px;
		width: 50%;
	}
	.buttonBoxCopy {
		margin-top: 20px;
		display: flex;
		justify-content: center;
		position: absolute;
		bottom: 0px;
		width: 50%;
	}
	.buttonVies {
		width: 110px;
		height: 28px;
		line-height: 28px;
		text-align: center;
		color: #fff;
		border: 1px solid #47e0e0;
		margin: 0 7px;
		cursor: pointer;
		user-select: none;
		transform: skewx(-15deg);
		display: flex;
    	justify-content: center;
		background: rgba(6, 23, 64, 1);
	}
	.buttonVies div {
		transform: skewx(15deg);
		font-size: 14px;
	}
	.buttonVies span {
		transform: skewx(15deg);
		font-size: 14px;
	}
	.buttonActive {
		color: #ffdd77;
	}
	#unitParentBox>>>.el-radio {
		margin-right: 5px;
	}
	#unitParentBox>>>.el-radio__label {
		padding-left: 3px;
	}
	.floatBoxTitle {
		font-size: 15px;
    	font-weight: 600;
		color: #47e0e0;
	}
	.floatingBox {
		display: flex;
		justify-content: flex-start;
		line-height: 24px;
	}
	.floatBoxLeft {
		width: 200px;
		color: #47e0e0;
	}
	.floatBoxRight {
		color: #47e0e0;
	}
	.topBoxAverage {
		width: 26px;
		height: 24px;
		line-height: 24px;
		border: 1px solid;
		border-radius: 18px;
		font-size: 12px;
		margin-bottom: 2px;
	}
</style>
